import React, { useEffect, useState } from "react";
import { TabsProps } from "@amzn/awsui-components-react/polaris/tabs";
import styles from "../../styles/mainContainer.module.scss"
import { TableComponent } from "../table/Table";
import {
    columnDefinitions,
    generateColumnsDynamically,
    tableDefinitions
} from "src/components/table/tableConfigs/tableConfig";
import { AppLayout, Badge, Button, DateRangePickerProps, Spinner } from "@amzn/awsui-components-react";
import { getTableProps } from "../table/tableConfigs/tableProps";
import { ITableData, ITableDatas, IUserActionsRequestResult } from "src/interfaces";
import { getRedriveActions } from "../redrive/RedriveActionComponents";
import { State } from "@hookstate/core";
import { IAppState } from "../store/appState";
import { UserActionsTable } from "../table/UserActionsAuditTable";
import { SearchBarContainer } from "../search/SearchBarContainer";
import { CheckPhotosTab } from "../table/CheckPhoto";

interface IMainContainerProps {
    appState: State<IAppState>;
    tableData: ITableDatas;
    handleStartLoading: any;
    userNameButtonLoading: boolean;
}

export function MainContainer({ tableData, appState, handleStartLoading, userNameButtonLoading }: IMainContainerProps,) {
    const firstTabId = Object.keys(tableDefinitions)[0];
    const [activeTabId, setActiveTabId] = useState(firstTabId);
    const [userActions, setUserActions] = useState<IUserActionsRequestResult | undefined>();
    const [userActionsDateRange, setUserActionsDateRange] =
        React.useState<DateRangePickerProps.Value | null>(null);

    const getLabel = (label: string, tableId: string) => {
        const loading = tableData[tableId].loadingMethodCountForTab > 0;
        return (
            <div>
                {label}
                {loading && <Spinner />}
                {!loading &&
                    <span style={{ marginLeft: "0.3rem" }}>
                        <Badge color="blue">{tableData[tableId].tableData.length}</Badge>
                    </span>}
            </div>);
    }
    useEffect(() => {
        if (!['redriveOptions', 'userActionsTable', 'checkPhotoTab'].includes(activeTabId)
            && !tableData[activeTabId].tableSupported) {
            setActiveTabId(firstTabId);
        }
    }, [tableData])


    const tabs: TabsProps.Tab[] = Object.entries(tableDefinitions).map(([key, tableDefinition]) => {
        const individualTableData: ITableData = tableData[key];
        const defaultColumnDefinitions = columnDefinitions[key];

        return {
            label: getLabel(tableDefinition.label, key),
            id: key,
            content: defaultColumnDefinitions ?
                <TableComponent
                    {...getTableProps(
                        tableDefinition.label,
                        individualTableData,
                        defaultColumnDefinitions,
                        tableDefinitions[key].dynamicallyLoaded ?
                            generateColumnsDynamically(individualTableData) : undefined,
                        tableDefinition.defaultQuery)}
                /> : null,
            disabled: !tableData[key].tableSupported
        }
    });

    const redriveContent = getRedriveActions(tableData);
    const userActionsTable = <UserActionsTable
        userActions={userActions}
        setUserActions={setUserActions}
        range={userActionsDateRange}
        setRange={setUserActionsDateRange}
    />

    // Add CheckPhoto tab
    if (appState.username.get()) {
        tabs.push({
            id: "checkPhotoTab",
            label:
                (<div className={styles.tabFont}>
                    Check Badge Photos
                </div>),
            content: appState.username.value ? 
                <CheckPhotosTab 
                    tableData={tableData} 
                    userLoading={userNameButtonLoading} 
                    userName={appState.username.value} 
                /> : <Spinner size="normal" />,
            disabled: false,
        });
    }

    if (appState.isActionAuthorized.get()) {
        tabs.push({
            id: "redriveOptions",
            label:
                (<div className={styles.tabFont}>
                    Redrive Options
                </div >),
            content: redriveContent,
            disabled: false,
        });

        tabs.push({
            id: "userActionsTable",
            label:
                (<div className={styles.tabFont} >
                    User Actions Audit
                </div>),
            content: userActionsTable,
            disabled: false,
        });
    }

    return <AppLayout
        navigation={
            <div className={styles.navigation}>
                {tabs.map((table, index) => {
                    const isSelected = table.id === activeTabId
                    return <Button
                        key={`table-${index}`}
                        data-testid={`table-${index}`}
                        onClick={() => {
                            setActiveTabId(table.id)
                        }}
                        variant={isSelected ? "normal" : "link"}
                        disabled={table.disabled}
                        fullWidth
                    >
                        <div className={isSelected ? styles.tableLabelSelected : styles.tableLabelNormal}>
                            {table.label}
                        </div>
                    </Button>
                }
                )}
            </div>
        }
        content={<>
            <SearchBarContainer
                startLoading={handleStartLoading}
                userNameButtonLoading={userNameButtonLoading} />
            <div className={styles.table}>
                {tabs.filter(table => table.id === activeTabId)[0].content}
            </div>
        </>}
        toolsHide
        disableContentPaddings
        navigationWidth={296}
    />
}