import { Button, FormField, Input } from '@amzn/awsui-components-react';
import React, { useState } from 'react';
import { IRequestData } from 'src/interfaces';
import styles from '../../styles/searchBar.module.scss';
import { invalidBadgeIdText } from '../utils';

interface ISearchBarProps {
    name: string;
    title: string;
    note?: string;
    inputPlaceholder: string;
    parameter: string;
    startLoading(query: IRequestData): void;
    isInputValid?(query: string): boolean;
    buttonLoading?: boolean;
}

export function SearchBar(props: ISearchBarProps) {
    const [searchInput, setSearchInput] = useState('');
    const [errorText, setErrorText] = useState('');
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const getData = async () => {
        if (searchInput.length > 0) {
            const trimmedInput = searchInput.trim();
            setSearchInput(trimmedInput)
            props.startLoading({
                param: props.parameter,
                value: trimmedInput
            } as IRequestData);
        }
    }

    const validateAndSet = (input: string) => {
        setSearchInput(input);
        if (props.isInputValid) {
            if (input && input.length > 0 && !props.isInputValid(input)) {
                setErrorText(invalidBadgeIdText);
                setButtonDisabled(true);
            } else {
                setErrorText('');
                setButtonDisabled(false);
            }
        }
    }

    return <div className={styles.searchBarRow}>
        <label className={styles.label}>{props.title}</label>

        <div className={styles.input}>
            <FormField
                stretch
                constraintText={props.note}
                errorText={errorText}>
                <Input
                    data-testid={`${props.name}-input`}
                    value={searchInput}
                    placeholder={props.inputPlaceholder}
                    onChange={({ detail }) => validateAndSet(detail.value)} />
            </FormField>
        </div>
        <div className={styles.button}>
            <Button
                onClick={getData}
                data-testid={`${props.name}-button`}
                disabled={buttonDisabled}
                loading={props.buttonLoading}
            >
                Search
            </Button>
        </div>
    </div>
}