import { Auth } from 'aws-amplify';
import axios, { AxiosError, AxiosResponse, HttpStatusCode } from 'axios';
import axiosRetry from 'axios-retry';
import { IMethodDefinition, tableDefinitions } from './components/table/tableConfigs/tableConfig';
import { API_URL } from './config/Globals';
import {
    IAPIResponse,
    IActionResponse,
    IActionResult,
    IUserActionsRequestResult,
    IUserActionsRequest,
    IUserActionLog,
    IRequestData
} from './interfaces';

let actionAbortController: AbortController;
let userActionDDBRequestAbortController: AbortController;
let queryAbortController: AbortController;


type ResultCallback = (apiResponse: IAPIResponse) => any;

const getAuthHeaders = async (): Promise<any> => {
    return {
        'Authorization': `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
    }
}

interface IMakeCallParams {
    path: string,
    method: "GET" | "POST",
    abortController: AbortController,
    parameters?: any,
    data?: any,
    turnOffRetry?: boolean
}

const makeCall = async ({
    path,
    method,
    abortController,
    parameters,
    data,
    turnOffRetry
}: IMakeCallParams): Promise<AxiosResponse<any, any>> => {

    axiosRetry(axios, {
        retryCondition: (error: AxiosError) => {
            return !turnOffRetry
                && (error.status !== HttpStatusCode.Ok
                    || error.response?.status !== HttpStatusCode.Ok
                    || error.message === 'CLIENT_TIMEOUT')
                && !axios.isCancel(error)
        },
        shouldResetTimeout: true,
        retries: 3,
        retryDelay: (retryCount) => {
            return retryCount * 1000;
        },
        onRetry: (retryCount, error, requestConfig) => {
            let code, statusText;
            if (error.response) {
                code = error.response.status;
                statusText = error.response.statusText;
            } else {
                code = error.code;
                statusText = error.message;
            }
            console.log(`Got status code ${code}, with message: ${statusText}. 
            Retrying request: ${error.config?.url}. Retry count: ${retryCount}`);
        }
    })
    const response = await axios(`${API_URL}v1/${path}`, {
        method,
        headers: {
            ...(await getAuthHeaders())
        },
        params: {
            ...parameters
        },
        data: {
            ...data
        },
        timeout: 29000,
        //Axios uses ECONNABORTED code for timeout which I didn't like, so I'm using custom one
        timeoutErrorMessage: 'CLIENT_TIMEOUT',
        signal: abortController?.signal,
    });
    return response;
}

const handleError = (err: any, method: string, resultCallback: ResultCallback) => {
    const methodTableNames = []
    for (const tableName in tableDefinitions) {
        if (tableDefinitions[tableName].methodDefinitions
            .filter(definition => definition.method === method).length) {
            methodTableNames.push(tableName);
        }
    }

    if (methodTableNames.length === 0) {
        methodTableNames.push(method);
    }

    if (err.message === 'CLIENT_TIMEOUT') {
        console.error(method, "Request timed out");
        const error = {
            method,
            errorMessage: "Request timed out",
        }
        resultCallback({
            data: [
                ...methodTableNames.map(tableName => {
                    return {
                        method,
                        tableName,
                        tableData: [],
                        errors: [error],
                        errorMessage: error.errorMessage
                    }
                })
            ],
            errors: []
        });
        return;
    } else if (err.response) {
        console.error(method, {
            data: err.response.data,
            status: err.response.status,
            statusText: err.response.statusText,
            headers: err.response.headers
        });
    } else {
        console.error(method, 'Error', err.message);
    }

    const error = {
        method,
        errorMessage: "Error occurred while requesting data"
    }

    resultCallback({
        data: [
            ...methodTableNames.map(tableName => {
                return {
                    method,
                    tableName,
                    tableData: [],
                    errors: [error],
                    errorMessage: error.errorMessage
                }
            })
        ],
        errors: [],
    });
}

const handleCall = (
    methodDefinition: IMethodDefinition,
    parameters: IRequestData,
    resultCallback: ResultCallback,
    abortController: AbortController) => {

    makeCall({
        path: `getData/method/${methodDefinition.method}`,
        method: "GET",
        abortController,
        parameters
    }).then((response: AxiosResponse) => {
        if (response.data) {
            console.log(methodDefinition.method, response.data);
            resultCallback(response.data);
        }
    }).catch(err => {
        if (!axios.isCancel(err)) {
            handleError(err, methodDefinition.method, resultCallback);
        }
    })
}

export const getDataFromApi = async (requestData: IRequestData, resultCallback: ResultCallback) => {
    if (queryAbortController) {
        queryAbortController.abort();
    }
    queryAbortController = new AbortController();

    const alreadyRunMethods: string[] = [];
    for (const tableName of Object.keys(tableDefinitions)) {
        for (const methodDefinition of tableDefinitions[tableName].methodDefinitions) {
            if (!alreadyRunMethods.includes(methodDefinition.method) &&
                methodDefinition.allowedParams?.includes(requestData.param)) {
                alreadyRunMethods.push(methodDefinition.method);
                handleCall(methodDefinition, requestData, resultCallback, queryAbortController);
            }
        }
    }

    logUserAction({
        actionName: "query",
        parameters: `${requestData.param}: ${requestData.value}`
    });
}

export const requestAction = async (redriveOption: string, parameters: Object): Promise<IActionResult> => {
    if (actionAbortController) {
        actionAbortController.abort();
    }
    actionAbortController = new AbortController();

    try {
        const response: AxiosResponse =
            await makeCall({
                path: `action/${redriveOption}`,
                method: "POST",
                abortController: actionAbortController,
                data: parameters,
                turnOffRetry: true
            });
        const result: IActionResponse = response.data;
        if (result.errors?.length > 0) {
            return {
                status: "error",
                message: result.errors[0].errorMessage!
            }
        } else {
            return {
                status: "success",
                message: result.data
            };
        }
    } catch (err: any) {
        console.error(err);
        if (err instanceof AxiosError && err.response?.data?.errors?.length > 0) {
            return {
                status: "error",
                message: err.response?.data.errors[0].errorMessage!
            }
        }
        return {
            status: "error",
            message: err.message,
        }
    }
}

export const isActionAuthorized = async (): Promise<boolean> => {
    try {
        const response: AxiosResponse =
            await makeCall({
                path: `action/isAuthorized`,
                method: "GET",
                abortController: new AbortController(),
                turnOffRetry: true
            });
        return response.status === HttpStatusCode.Ok;
    } catch (err: any) {
        if (!(err instanceof AxiosError && err.response?.status === HttpStatusCode.Forbidden)) {
            console.error(err);
        }
        return false;
    }
}

export const getUserActions = async (parameters: IUserActionsRequest): Promise<IUserActionsRequestResult> => {
    if (userActionDDBRequestAbortController) {
        userActionDDBRequestAbortController.abort();
    }
    userActionDDBRequestAbortController = new AbortController();

    logUserAction({
        actionName: "queryUserActionsAudit",
        parameters
    });

    try {
        const response: AxiosResponse =
            await makeCall({
                path: 'getData/method/UserActions',
                method: "POST",
                abortController: userActionDDBRequestAbortController,
                data: parameters
            });
        return response.data;
    } catch (err: any) {
        if (axios.isCancel(err)) {
            return {
                data: [],
                errors: []
            }
        }
        console.error(err);
        if (err instanceof AxiosError && err.response?.data?.errors?.length > 0) {
            return err.response?.data
        }
        return {
            data: [],
            errors: [{
                method: "UserActions",
                errorMessage: err.message
            }]
        }
    }
}

export const logUserAction = async (userActionLog: IUserActionLog): Promise<void> => {
    await makeCall({
        path: 'logUserAction',
        method: "POST",
        abortController: new AbortController(),
        data: userActionLog,
        turnOffRetry: true
    });
}

export const searchByUserName = async (requestData: IRequestData, resultCallback: ResultCallback): Promise<void> => {
    if (queryAbortController) {
        queryAbortController.abort();
    }
    queryAbortController = new AbortController();
    handleCall(
        {
            method: 'LookupByUserName',
            allowedParams: ["userName"]
        },
        requestData,
        resultCallback,
        queryAbortController);
}