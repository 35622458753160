import { Box, Button, DateRangePickerProps, Flashbar, Table } from "@amzn/awsui-components-react"
import React, { useEffect, useState } from "react"
import { getColumnDefinition } from "./tableConfigs/tableConfig"
import { getUserActions } from "src/api";
import DateRangePickerComponent from "./DateRangePickerComponent";
import { getTableComponentConfig } from "./tableConfigs/tableComponentConfig";
import styles from "../../styles/userActionsAudit.module.scss"
import { dateRangeToStartAndEndDates, getTableHeader } from "../utils";
import { IUserActionsRequestResult } from "src/interfaces";

interface IUserActionsTableProps {
    userActions: IUserActionsRequestResult | undefined
    setUserActions: any
    range: DateRangePickerProps.Value | null
    setRange: React.Dispatch<React.SetStateAction<DateRangePickerProps.Value | null>>
}

export function UserActionsTable(
    { userActions, setUserActions, range, setRange }: IUserActionsTableProps) {

    const [searchDisabled, setSearchDisabled] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const columnDefinitions = [
        getColumnDefinition("actionId", "Action Id"),
        getColumnDefinition("username", "User Name"),
        getColumnDefinition("actionName", "Action Name"),
        getColumnDefinition("parameters", "Parameters", 500),
        getColumnDefinition("timestampUTC", "Timestamp UTC"),
        getColumnDefinition("timestamp", "Timestamp"),
    ];

    useEffect(() => {
        setSearchDisabled(!range);
    }, [range]);

    async function retrieveData() {
        clearData();
        setLoading(true);
        const rangeDates = dateRangeToStartAndEndDates(range);
        const result = await getUserActions({
            startTime: rangeDates.startTime!,
            endTime: rangeDates.endTime!,
        });
        setUserActions(result);
        setLoading(false);
    }

    async function retrieveNextPage() {
        const rangeDates = dateRangeToStartAndEndDates(range);
        const result = await getUserActions({
            startTime: rangeDates.startTime!,
            endTime: rangeDates.endTime!,
            lastEvaluatedKey: userActions?.lastEvaluatedKey
        });
        setUserActions((prevData: IUserActionsRequestResult) => {
            const newData: any[] = [
                ...prevData?.data!,
                ...result.data
            ]
            return {
                ...result,
                data: newData
            };
        });
    }

    function clearData() {
        setUserActions({
            data: [],
            errors: []
        })
    }

    function clearErrors() {
        setUserActions((prevData: IUserActionsRequestResult) => {
            return {
                data: prevData?.data,
                lastEvaluatedKey: prevData?.lastEvaluatedKey
            }
        });
    }

    const headerComponent = <div>
        {getTableHeader("User Actions Audit")}
        {
            userActions?.errors && userActions?.errors.length > 0 && <div className={styles.errorFlashbar}>
                <Flashbar items={[
                    {
                        type: 'error',
                        content: userActions?.errors.length > 0 ? userActions?.errors[0].errorMessage : "Unknown error occurred",
                        onDismiss: () => clearErrors(),
                        dismissible: true
                    }
                ]} />
            </div>
        }
        {userActions?.lastEvaluatedKey && <div className={styles.nextPageRetrieveFlasbar}>
            <Flashbar items={[{
                type: 'info',
                content: <>
                    This table has more items to retrieve. To retrieve the next page of items, choose Retrieve next page.
                    <span className={styles.nextPageRetrieveButton}>
                        <Button onClick={retrieveNextPage}>Retrieve next page</Button>
                    </span>
                </>
            }]} />
        </div>}
        <div className={styles.datePickerContainer}>
            <div className={styles.datePicker}><DateRangePickerComponent value={range} setValue={setRange} /></div>
            <Button disabled={searchDisabled} onClick={retrieveData}>Search</Button>
        </div>
    </div>

    return <Table
        {...getTableComponentConfig("UserActions", userActions?.data ?? [], columnDefinitions)}
        sortingDisabled
        header={headerComponent}
        loadingText="Loading resources"
        loading={loading}
        empty={
            <Box
                padding={{ bottom: "s" }}
                variant="p"
                color="inherit"
            >
                {"No resources to display"}
            </Box>
        }
    />
}