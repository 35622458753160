import React from "react";
import { TopNavigation } from "@amzn/awsui-components-react/polaris";
import { appBaseState } from "../store/appState";
import { useHookstate } from "@hookstate/core";
import { NAVBAR_LOGO, STAGE_NAME } from "src/config/Globals";

export function NavigationBar(props: any) {
    const appState = useHookstate(appBaseState);
    const title = STAGE_NAME === "prod" ? "Lanyard" : `Lanyard::${STAGE_NAME}`;
    return (<div className="awsui" id="header" style={{ position: "sticky", top: 0, zIndex: 1002, margin: 0 }}>
        <TopNavigation
            identity={{
                href: "#",
                title: title,
                logo: {
                    src: NAVBAR_LOGO,
                    alt: title
                }
            }}
            utilities={[
                {
                    type: "button",
                    text: "Guardian Hub",
                    external: true,
                    href: "https://guardian.gso.amazon.dev/"
                },
                {
                    type: "menu-dropdown",
                    text: "Help",
                    items: [
                        {
                            id: "report-issue",
                            text: "Report an issue",
                            href: "https://t.corp.amazon.com/create/templates/da62e14d-b88a-46d5-af79-fcea6230e184",
                            external: true
                        },
                        {
                            id: "request-feature",
                            text: "Request a feature",
                            href: "https://w.amazon.com/bin/view/AccSys/Lanyard#HRequestafeature",
                            external: true
                        },
                        {
                            id: "wiki",
                            text: "Wiki",
                            href: "https://w.amazon.com/bin/view/AccSys/Lanyard",
                            external: true
                        },
                    ]
                },
                {
                    type: "button",
                    text: appState.username.get(),
                    iconName: "user-profile",
                }
            ]}
            i18nStrings={{
                searchIconAriaLabel: "Search",
                searchDismissIconAriaLabel: "Close search",
                overflowMenuTriggerText: "More",
                overflowMenuTitleText: "All",
                overflowMenuBackIconAriaLabel: "Back",
                overflowMenuDismissIconAriaLabel: "Close menu"
            }}
        /></div>
    );

}