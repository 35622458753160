import * as React from "react";
import Flashbar, { FlashbarProps } from "@amzn/awsui-components-react/polaris/flashbar";
import { IRequestError } from "src/interfaces";
import { ExpandableSection } from "@amzn/awsui-components-react";
import styles from "../../styles/searchErrorFlashbar.module.scss";

interface IFlashBarProps {
    items: Set<IRequestError>;
    setItems: Function;
}

export function SearchErrorFlashbar(props: IFlashBarProps) {
    const content = [];
    for (const error of props.items) {
        content.push(
            <ExpandableSection
                headerText={<div className={styles.expandHeaderStyle}>{`${error.method} method returned with error`}</div>}
                key={error.method}>
                <ul className={styles.listStyle}>
                    <li><strong>Region:</strong> {error.sourceRegion}</li>
                    <li><strong>Message:</strong> {error.errorMessage}</li>
                    <li><strong>Status:</strong> {error.status}</li>
                    <li><strong>Data:</strong> {JSON.stringify(error.errorData)}</li>
                </ul>
            </ExpandableSection>
        );
    }
    const items: FlashbarProps.MessageDefinition[] = [
        {
            type: "error",
            dismissible: true,
            dismissLabel: "Dismiss message",
            onDismiss: () => props.setItems([]),
            content: <div>{content}</div>,
            id: "message_1"
        }
    ];
    return <Flashbar items={items} />;
}