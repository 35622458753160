import React, { useState } from "react";
import RedriveField from "./autosuggestField";
import { ITableDatas } from "src/interfaces";
import { getTableHeader, invalidBadgeIdText, isBadgeIdInputValid } from "../utils";
import { Container, FlashbarProps, Link, Select, Textarea } from "@amzn/awsui-components-react/polaris";
import styles from "../../styles/redriveContainer.module.scss";
import { RedriveActionContainer } from "./RedriveActionContainer";

export interface IRedriveActionComponent {
    fields: JSX.Element
    methodName: string
    isInvalid: (fieldData: IRedriveFieldData) => boolean
    getDataForRequest: (fieldData: IRedriveFieldData) => IRedriveFieldData // returns a subset of what's available in the interface
    getAdditionalFlashbarContent?: (
        fieldData: IRedriveFieldData,
        onDismiss: () => void,
        isRequestSuccessful: boolean) => FlashbarProps.MessageDefinition[]
}

interface IRedriveFieldData {
    employeeId?: string
    badgeId?: string
    employeeIdList?: string
    stack?: string
    badgeIdRegionPairs?: string
}
export interface IRedriveComponentData {
    tableDatas: ITableDatas
    fieldData: IRedriveFieldData
    setFieldData: ((fieldData: React.SetStateAction<IRedriveFieldData>) => void)
}

export function getRedriveActions(tableDatas: ITableDatas) {
    const [selectedOption, setSelectedOption] = useState<any>({ label: "Choose a redrive option", value: "Choose a redrive option" });
    const redriveComponents: { [key: string]: IRedriveActionComponent | JSX.Element } = {
        "Redrive a badge to Feenics through Badge Sync":
            getComponent(getRedriveBadgeToFeenicsViaBadgeSync),

        "Redrive an employee to Feenics through People Sync":
            getComponent(getRedriveEmployeeToFeenicsViaPeopleSync),

        "Redrive a badge to Badge_Assignment_Tracking DDB table":
            getComponent(getRedriveBadgeToBadge_Assignment_Tracking),

        "Reset access revocation date in PeopleAPIV2-EmployeeData DDB":
            getComponent(getResetAccessRevocationInPeopleAPIV2EmployeeDataDDB),

        "Redrive employee from PAPI to PeopleAPIV2-EmployeeData DDB":
            getComponent(getRedrivePAPIToPeopleAPIV2_EmployeeData),

        "Redrive an employee through the Import Process":
            getComponent(getRedriveAnEmployeeThroughTheImportProcess),

        "Redrive access levels to Feenics":
            getComponent(getRedriveAccessLevelsToFeenics),

        "Force redrive badge between stacks":
            getComponent(getForceRedriveBadgeBetweenStacks),
    };


    function getComponent(componentFunction: (componentData: IRedriveComponentData) => IRedriveActionComponent) {
        const [fieldData, setFieldData] = useState<IRedriveFieldData>({});
        const [flashbarData, setFlashbarData] = useState<FlashbarProps.MessageDefinition[]>([]);
        return <RedriveActionContainer
            redriveComponent={componentFunction({ tableDatas, fieldData, setFieldData })}
            tableDatas={tableDatas}
            fieldData={fieldData}
            setFieldData={setFieldData}
            flashbarData={flashbarData}
            setFlashbarData={setFlashbarData}
        />
    }

    return <Container header={getTableHeader("Redrive Options")}>
    <div className={styles.redriveComponent}>
        <div className={styles.redriveSelector}>
            <Select
                selectedOption={selectedOption}
                onChange={({ detail }) => {
                    setSelectedOption(detail.selectedOption);
                }}
                options={Object.keys(redriveComponents).map((name) => ({ label: name, value: name }))}
            />
        </div>
        {redriveComponents[selectedOption.label]}
    </div>
    </Container>;
}

function getBadgeIdField(
    tableDatas: ITableDatas,
    fieldData: IRedriveFieldData,
    setFieldData: (fieldData: React.SetStateAction<IRedriveFieldData>) => void) {

    const getErrorText = (value: string) => {
        return isBadgeIdInputValid(value) ? undefined : invalidBadgeIdText;
    }
    const errorText = (!fieldData.badgeId) ? undefined : getErrorText(fieldData.badgeId);

    return <RedriveField
        label="Badge ID"
        key="badgeIdRedriveField"
        options={getSuggestions('Badge', 'badgeId', tableDatas)}
        onChange={(value) => {
            const trimmedValue = value.trim();
            setFieldData((prev) => {
                return {
                    ...prev,
                    badgeId: trimmedValue
                }
            });
        }}
        value={fieldData.badgeId ?? ""}
        errorText={errorText}
    />
}

function getEmployeeIdField(
    tableDatas: ITableDatas,
    fieldData: IRedriveFieldData,
    setFieldData: (fieldData: React.SetStateAction<IRedriveFieldData>) => void) {

    return <RedriveField
        label="Employee ID"
        key="employeeIdRedriveField"
        options={getSuggestions('Cardholder', 'employeeId', tableDatas)}
        onChange={(value) => {
            const trimmedValue = value.trim();
            setFieldData((prev) => {
                return {
                    ...prev,
                    employeeId: trimmedValue,
                }
            });
        }}
        value={fieldData.employeeId ?? ""}
    />
}

function getStackSelectorField(
    fieldData: IRedriveFieldData,
    setFieldData: (fieldData: React.SetStateAction<IRedriveFieldData>) => void,
    label?: string) {

    return <div className={styles.stackSelector}>
        <label> {label ?? "Stack to perform redrive:"}</label>
        <Select
            selectedOption={{ value: fieldData.stack }}
            options={[
                { value: "ACS" },
                { value: "OPS" }
            ]}
            onChange={(event) => {
                setFieldData((prev) => {
                    return {
                        ...prev,
                        stack: event.detail.selectedOption.value
                    }
                });
            }}
        />
    </div>;
}

function getSuggestions(tableName: string, fieldName: string, tableDatas: ITableDatas) {
    if (tableDatas[tableName]) {
        const options = [...new Set(tableDatas[tableName].tableData.map(row => `${row[fieldName]}`))]
            .map(employeeId => ({
                value: employeeId,
            }));
        return options;
    }
    return [];
}

function getRedriveBadgeToFeenicsViaBadgeSync(
    { fieldData, setFieldData, tableDatas }: IRedriveComponentData): IRedriveActionComponent {

    const badgeIdField = getBadgeIdField(tableDatas, fieldData, setFieldData,);
    const employeeIdField = getEmployeeIdField(tableDatas, fieldData, setFieldData);

    return {
        fields: <>
            {employeeIdField}
            {badgeIdField}
        </>,
        methodName: "redriveBadgeToFeenicsViaBadgeSync",
        isInvalid(fieldData) {
            return !fieldData.badgeId || !isBadgeIdInputValid(fieldData.badgeId) || !fieldData.employeeId;
        },
        getDataForRequest(fieldData) {
            return {
                employeeId: fieldData.employeeId,
                badgeId: fieldData.badgeId
            }
        },
    }
}

function getRedriveEmployeeToFeenicsViaPeopleSync(
    { fieldData, setFieldData, tableDatas }: IRedriveComponentData): IRedriveActionComponent {

    return {
        fields: getEmployeeIdField(tableDatas, fieldData, setFieldData),
        methodName: "redriveEmployeeToFeenicsViaPeopleSync",
        isInvalid(fieldData): boolean {
            return !fieldData.employeeId;
        },
        getDataForRequest(fieldData) {
            return {
                employeeId: fieldData.employeeId
            }
        },
    }
}

function getRedriveBadgeToBadge_Assignment_Tracking(
    { fieldData, setFieldData }: IRedriveComponentData): IRedriveActionComponent {

    const textArea = <div className={styles.textArea}>
        <label>Badge ID and Region pairs:</label>
        <div>
            <Textarea
                value={fieldData.badgeIdRegionPairs ?? ""}
                onChange={(event) => {
                    const value = event.detail.value;
                    setFieldData((prev) => {
                        return {
                            ...prev,
                            badgeIdRegionPairs: value
                        }
                    });
                }}
                invalid={isPairsFieldInvalid(fieldData.badgeIdRegionPairs, true)}
            />
            <div className={styles.textAreaNote}>
                Example input:
                <div style={{ marginLeft: "0.2em" }}>
                    <div>1234567,APAC</div>
                    <div>1234567,EMEA</div>
                </div>
            </div>
        </div>
    </div>;

    function isPairsFieldInvalid(pairs?: string, field?: boolean) {
        if (!pairs || pairs.length === 0) {
            return !field;
        }
        for (const pair of pairs.split("\n")) {
            if (!/^((\d|\w)+,(APAC|EMEA|AMER|GLOBAL)$)/.test(pair)) {
                return true;
            }
        }
        return false;
    }

    return {
        fields: <>
            {getStackSelectorField(fieldData, setFieldData)}
            {textArea}
        </>,
        methodName: "redriveBadgeToBadge_Assignment_Tracking",
        isInvalid(fieldData) {
            return isPairsFieldInvalid(fieldData.badgeIdRegionPairs) || !fieldData.badgeIdRegionPairs || !fieldData.stack
        },
        getDataForRequest(fieldData) {
            return {
                stack: fieldData.stack,
                badgeIdRegionPairs: fieldData.badgeIdRegionPairs
            }
        },
    }
}

function getResetAccessRevocationInPeopleAPIV2EmployeeDataDDB(
    { fieldData, setFieldData, tableDatas }: IRedriveComponentData): IRedriveActionComponent {

    return {
        fields: getEmployeeIdField(tableDatas, fieldData, setFieldData),
        methodName: "resetAccessRevocationInPeopleAPIV2EmployeeDataDDB",
        isInvalid(fieldData) {
            return !fieldData.employeeId;
        },
        getDataForRequest(fieldData) {
            return {
                employeeId: fieldData.employeeId
            }
        },
        getAdditionalFlashbarContent(fieldData, onDismiss, isRequestSuccessful) {
            if (!isRequestSuccessful) {
                return [];
            }
            const title = "Employee active after access revocation date"
            const description =
                fieldData.employeeId ? `Employee with PSID: ${fieldData.employeeId} was set as active after the access revocation date. Please investigate and correct the error.` : ""

            return [
                {
                    type: "warning",
                    dismissible: true,
                    onDismiss,
                    content: <>This is a temporary fix.&nbsp;
                        <Link
                            color="inverted"
                            external
                            href={`https://t.corp.amazon.com/create/options?ticket_type=Regular+Ticket&severity=FIVE&category=HRIS+-+HR+Systems&type=PeopleSoft+Application&item=Technical+Issue&title=${title}&description=${description}&group=&assignee=&asin=&quantity=&isd=&upc=&binding=&vendor_id=&purchase_order_id=&stock_number=&ship_origin=&invoice_number=&physical_location=&tracking_number=&bol_number=&picture_file_imdex_location=&vendor_info_title=`}>
                            You should cut a ticket to HR in order to permanently fix the problem.</Link> </>
                }
            ]
        }
    }
}

function getRedrivePAPIToPeopleAPIV2_EmployeeData(
    { fieldData, setFieldData, tableDatas }: IRedriveComponentData): IRedriveActionComponent {

    return {
        fields: getEmployeeIdField(tableDatas, fieldData, setFieldData),
        methodName: "redrivePAPIToPeopleAPIV2_EmployeeData",
        isInvalid(fieldData) {
            return !fieldData.employeeId
        },
        getDataForRequest(fieldData) {
            return {
                employeeId: fieldData.employeeId
            }
        },
    }
}

function getRedriveAnEmployeeThroughTheImportProcess(
    { fieldData, setFieldData }: IRedriveComponentData): IRedriveActionComponent {

    const textArea = <div className={styles.textArea}>
        <label>Employee ID list:</label>
        <div>
            <Textarea
                value={fieldData.employeeIdList ?? ""}
                onChange={(event) => {
                    const value = event.detail.value;
                    setFieldData((prev) => {
                        return {
                            ...prev,
                            employeeIdList: value,
                        }
                    });
                }}
                invalid={isInvalid(fieldData, true)}
            />
            <div className={styles.textAreaNote}>
                Example input:
                <div style={{ marginLeft: "0.2em" }}>
                    <div>1234567</div>
                    <div>1234568</div>
                </div>
            </div>
        </div>
    </div>;

    function isInvalid(fieldData: IRedriveFieldData, field?: boolean) {
        const emplIdListString = fieldData.employeeIdList;
        if (!emplIdListString || emplIdListString.length === 0) {
            return !field;
        }
        for (const id of emplIdListString.split("\n")) {
            if (!/^((\d|\w)+$)/.test(id)) {
                return true;
            }
        }
        return false;
    }

    return {
        fields: textArea,
        methodName: "redriveAnEmployeeThroughTheImportProcess",
        isInvalid,
        getDataForRequest(fieldData) {
            return {
                employeeIdList: fieldData.employeeIdList
            }
        },
    }
}

function getRedriveAccessLevelsToFeenics(
    { fieldData, setFieldData, tableDatas }: IRedriveComponentData): IRedriveActionComponent {

    return {
        fields: getEmployeeIdField(tableDatas, fieldData, setFieldData),
        methodName: "redriveAccessLevelsToFeenics",
        isInvalid(fieldData) {
            return !fieldData.employeeId
        },
        getDataForRequest(fieldData) {
            return {
                employeeId: fieldData.employeeId
            }
        },
    }
}

function getForceRedriveBadgeBetweenStacks(
    { fieldData, setFieldData, tableDatas }: IRedriveComponentData): IRedriveActionComponent {

    const badgeIdField = getBadgeIdField(tableDatas, fieldData, setFieldData);

    return {
        fields: <>
            {getStackSelectorField(fieldData, setFieldData, "Stack to perform redrive from:")}
            {badgeIdField}
        </>,
        methodName: "forceRedriveBadgeBetweenStacks",
        isInvalid(fieldData) {
            return !fieldData.badgeId || !isBadgeIdInputValid(fieldData.badgeId) || !fieldData.stack
        },
        getDataForRequest(fieldData) {
            return {
                stack: fieldData.stack,
                badgeId: fieldData.badgeId
            }
        },
    }
}