import * as React from "react";
import Autosuggest, { AutosuggestProps } from "@amzn/awsui-components-react/polaris/autosuggest";
import styles from "../../styles/redriveContainer.module.scss";

interface IAutosuggestComponentProps {
    options: AutosuggestProps.Options
    value: string
    onChange: (value: string) => void
    label: string
    errorText?: string
}

export default (props: IAutosuggestComponentProps) => {
    return (
        <div className={styles.field}>
            <label className={styles.label}>{props.label}</label>
            <Autosuggest
                onChange={({ detail }) => props.onChange(detail.value)}
                value={props.value ?? ""}
                options={props.options}
                enteredTextLabel={value => `Use: "${value}"`}
                ariaLabel="Autosuggest example with suggestions"
                placeholder="Enter value"
                empty="No matches found"
                errorText={props.errorText}
                statusType={props.errorText ? 'error' : undefined}
                invalid={props.errorText !== undefined}
            />
        </div>
    );
}