import React from "react";
import { Icon, TextContent } from "@amzn/awsui-components-react"
import { STAGE_NAME } from "src/config/Globals";

export const getPhotos = (userAlias: string) => {
    return [
        {
            name: 'DCSS',
            description: `Obtained via S3 from the DCSS bucket. This photo is not expected to be populated if
                the employee has not had a badge photo taken in an AWS Data Center facility.`,
            source: `https://api.${STAGE_NAME}.checkphoto.gso.amazon.dev/?login=${userAlias}&source=DCSS`,
            sourceName: 'DCSS'
        },
        {
            name: 'Badge Photo Service API',
            description: 'Photo pulled from BadgePhotoService API query',
            source: `https://api.${STAGE_NAME}.checkphoto.gso.amazon.dev/?login=${userAlias}&source=BPS`,
            sourceName: 'Badge Photo Service'
        },
        {
            name: 'Internal CDN',
            description: 'Photo pulled from BadgePhotoService CDN service. This photo comes directly from the CDN and does not get sent to CheckPhoto.',
            source: `https://badgephotos.corp.amazon.com/?login=${userAlias}&fullsize=true`,
            sourceName: 'Badge Photo Service (CDN)'
        },
        {
            name: 'ANT',
            description: (
                <TextContent>
                    <div>Photo grabbed from ANT attribute. Photo queries to ANT can take up to 30 seconds.</div>
                    <div style={{ marginTop: 5, display: 'inline-block' }} >
                        <Icon name="status-warning" size="small" variant="error"/>
                         This picture is cached by CheckPhoto and refresh queries to ANT will be delayed
                        by at least 5 minutes.
                    </div>
                </TextContent>
            ),
            source: `https://api.${STAGE_NAME}.checkphoto.gso.amazon.dev/?login=${userAlias}&source=ANT`,
            sourceName: 'ANT'
        },
        {
            name: 'OPS Lenel (Global)',
            description: 'Photo pulled from OPS Lenel global region.',
            source: `https://api.${STAGE_NAME}.checkphoto.gso.amazon.dev/?login=${userAlias}&source=OPS&region=GLOBAL`,
            sourceName: 'OPS-Global'
        },
        {
            name: 'OPS Lenel (AMER)',
            description: 'Photo pulled from OPS Lenel AMER region.',
            source: `https://api.${STAGE_NAME}.checkphoto.gso.amazon.dev/?login=${userAlias}&source=OPS&region=AMER`,
            sourceName: 'OPS-AMER'
        },
        {
            name: 'OPS Lenel (APAC)',
            description: 'Photo pulled from OPS Lenel APAC region.',
            source: `https://api.${STAGE_NAME}.checkphoto.gso.amazon.dev/?login=${userAlias}&source=OPS&region=APAC`,
            sourceName: 'OPS-APAC'
        },
        {
            name: 'OPS Lenel (EMEA)',
            description: 'Photo pulled from OPS Lenel EMEA region.',
            source: `https://api.${STAGE_NAME}.checkphoto.gso.amazon.dev/?login=${userAlias}&source=OPS&region=EMEA`,
            sourceName: 'OPS-EMEA'
        },
        {
            name: 'ACS Lenel (Global)',
            description: 'Photo pulled from ACS Lenel global region.',
            source: `https://api.${STAGE_NAME}.checkphoto.gso.amazon.dev/?login=${userAlias}&source=ACS&region=GLOBAL`,
            sourceName: 'ACS-Global'
        },
        {
            name: 'ACS Lenel (AMER)',
            description: 'Photo pulled from ACS Lenel AMER region.',
            source: `https://api.${STAGE_NAME}.checkphoto.gso.amazon.dev/?login=${userAlias}&source=ACS&region=AMER`,
            sourceName: 'ACS-AMER'
        },
        {
            name: 'ACS Lenel (APAC)',
            description: 'Photo pulled from ACS Lenel APAC region.',
            source: `https://api.${STAGE_NAME}.checkphoto.gso.amazon.dev/?login=${userAlias}&source=ACS&region=APAC`,
            sourceName: 'ACS-APAC'
        },
        {
            name: 'ACS Lenel (EMEA)',
            description: 'Photo pulled from ACS Lenel EMEA region.',
            source: `https://api.${STAGE_NAME}.checkphoto.gso.amazon.dev/?login=${userAlias}&source=ACS&region=EMEA`,
            sourceName: 'ACS-EMEA'
        },
    ]
}