import React, { useState } from 'react';
import { useHookstate } from '@hookstate/core';
import { appBaseState, IAppState } from './store/appState';
import { BaseLayout } from './baseComponents/BaseLayout';
import { MainContainer as MainContainer } from './baseComponents/MainContainer';
import { getDataFromApi, searchByUserName } from '../api';
import { AuthenticateUser } from './baseComponents/AuthenticateUser';
import { IAPIResponse, IRequestData, IRequestError, ITableDatas } from 'src/interfaces';
import { SearchErrorFlashbar } from './search/SearchErrorFlashbar';
import { getBlankTableData, tableDefinitions, tableSupportsParam, tableSupportsParamCount } from './table/tableConfigs/tableConfig';
import { UserNameSearchModal } from './search/UserNameSearchModal';

export function App() {
    const appState = useHookstate<IAppState>(appBaseState);
    const [tableData, setTableData] = useState<ITableDatas>(getBlankTableData());
    const [flashbarErrors, setFlashbarErrors] = useState<Set<IRequestError>>(new Set());
    const [userNameButtonLoading, setUserNameButtonLoading] = useState<boolean>(false);
    const [userNameSearchResult, setUserNameSearchResult] = useState<any[]>([]);

    const handleSearchByUserName = (apiResponse: IAPIResponse) => {
        setUserNameButtonLoading(false);
        if (apiResponse.data.length === 1) {
            const tableData = apiResponse.data[0].tableData;
            if (tableData.length === 1) {
                handleStartLoading({
                    param: "employeeId",
                    value: tableData[0].employeeId
                });
            } else if (tableData.length > 0) {
                setUserNameSearchResult(tableData);
            }
        }

        setFlashbarErrors(prev => {
            return new Set([
                ...prev,
                ...apiResponse.errors,
                ...apiResponse.data.flatMap(resultData => resultData.errors)
            ])
        });
    }

    const handleStartLoading = (query: IRequestData) => {
        setUserNameButtonLoading(false);
        const loadingDataState = {
            ...getBlankTableData()
        };
        setFlashbarErrors(new Set());

        if (query.param === 'userName') {
            setTableData(loadingDataState);
            setUserNameButtonLoading(true);
            searchByUserName(query, handleSearchByUserName);
            return;
        }

        for (const tableName of Object.keys(tableDefinitions)) {
            if (tableSupportsParam(tableName, query.param)) {
                loadingDataState[tableName].loadingMethodCountForTab = tableSupportsParamCount(tableName, query.param);
            } else {
                loadingDataState[tableName].tableSupported = false
            }
        }
        setTableData(loadingDataState);

        getDataFromApi(query,
            (result) => {
                handleSetTableData(result);
            });
    }

    const handleSetTableData = (apiResponse: IAPIResponse) => {
        apiResponse.data.map((resultData) => {
            setTableData((prevTableDatas: ITableDatas) => {
                const prev = prevTableDatas[resultData.tableName];
                const offset = prev.tableData.length;
                const tableDataWithIndexes = resultData.tableData.map((item: Object, index: number) => ({
                    id: index + offset,
                    ...item
                }));
                return {
                    ...prevTableDatas,
                    [resultData.tableName]: {
                        ...prev,
                        loadingMethodCountForTab: prev.loadingMethodCountForTab - 1,
                        tableData: prev.tableData.concat(tableDataWithIndexes),
                        errorMessage: resultData.errorMessage
                    }
                }
            });
        })

        setFlashbarErrors(prev => {
            return new Set([
                ...prev,
                ...apiResponse.errors,
                ...apiResponse.data.flatMap(resultData => resultData.errors)
            ])
        });
    }

    return (
        <AuthenticateUser appState={appState}>
            {userNameSearchResult.length > 0 &&
                <UserNameSearchModal
                    userNameSearchResult={userNameSearchResult}
                    setUserNameSearchResult={setUserNameSearchResult}
                    handleStartLoading={handleStartLoading} />}
            <BaseLayout>
                {flashbarErrors.size > 0 && <SearchErrorFlashbar items={flashbarErrors} setItems={setFlashbarErrors} />}
                <MainContainer
                    tableData={tableData}
                    appState={appState}
                    handleStartLoading={handleStartLoading}
                    userNameButtonLoading={userNameButtonLoading} />
            </BaseLayout>
        </AuthenticateUser>
    );
}