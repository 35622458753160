import React from "react";
import Table from "@amzn/awsui-components-react/polaris/table";
import Box from "@amzn/awsui-components-react/polaris/box";
import { getTableHeader } from "../utils";

export interface ITableProps {
    loading: boolean
    tableComponentConfig: any
    errorMessage?: string
    label: string
}

export function TableComponent(props: ITableProps) {
    return (
        <Table<any>
            header={getTableHeader(props.label)}
            items={[]}
            loading={props.loading && props.tableComponentConfig.items.length === 0}
            loadingText="Loading resources"
            selectionType="multi"
            trackBy="id"
            {...props.tableComponentConfig}
            empty={
                <Box
                    padding={{ bottom: "s" }}
                    variant="p"
                    color="inherit"
                >
                    {props.errorMessage || "No resources to display"}
                </Box>
            }
        />
    )
}