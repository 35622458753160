import { Link, Table } from "@amzn/awsui-components-react/polaris";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import React from "react";
import { IRequestData } from "src/interfaces";

interface IUserNameSearchModalProps {
    userNameSearchResult: any[]
    setUserNameSearchResult: (value: React.SetStateAction<any[]>) => void
    handleStartLoading: (query: IRequestData) => void
}

export function UserNameSearchModal({
    userNameSearchResult,
    setUserNameSearchResult,
    handleStartLoading
}: IUserNameSearchModalProps) {
    const linkedData = userNameSearchResult.map((entry) => {
        const newEntry = { ...entry };
        for (const key of Object.keys(entry)) {
            newEntry[key] = <Link
                href="#"
                onFollow={() => {
                    handleStartLoading({
                        param: "employeeId",
                        value: entry.employeeId
                    });
                    setUserNameSearchResult([]);
                }}
                variant="secondary"
            >
                <div style={{textAlign: "center"}}>{entry[key]}</div>
            </Link>;
        }
        return newEntry;
    })

    return <Modal
        onDismiss={() => setUserNameSearchResult([])}
        visible
        header="Employees found by User Name"
        size="max"
    >
        <Table
            columnDefinitions={[
                {
                    header: "First Name",
                    cell: (cell: any) => cell.firstName,
                },
                {
                    header: "Last Name",
                    cell: (cell: any) => cell.lastName,
                },
                {
                    header: "User Name",
                    cell: (cell: any) => cell.userName,
                },
                {
                    header: "Employee Id",
                    cell: (cell: any) => cell.employeeId,
                },
                {
                    header: "Employee Status",
                    cell: (cell: any) => cell.employeeStatus,
                },
                {
                    header: "Hire Date",
                    cell: (cell: any) => cell.hireDate,
                },
                {
                    header: "Termination Date",
                    cell: (cell: any) => cell.terminationDate,
                }
            ]}
            items={linkedData}
        />
    </Modal>
}