import { Container } from "@amzn/awsui-components-react";

import React from "react";
import { SearchBar } from "./SearchBar";
import styles from "../../styles/searchBarContainer.module.scss";
import { IRequestData } from "src/interfaces";
import { isBadgeIdInputValid } from "../utils";

interface ISearchBarContainerProps {
    startLoading(query: IRequestData): void;
    userNameButtonLoading: boolean
}

export function SearchBarContainer(props: ISearchBarContainerProps) {
    return (
        <div className={`${styles.container}`}>
            <Container
                header={<div className={styles.header}>
                    Cardholder Search
                </div>}
            >
                <SearchBar
                    key="userNameSearchBar"
                    name="userNameSearchBar"
                    title="Search by User Name"
                    inputPlaceholder="Example User Name: imghe"
                    parameter='userName'
                    startLoading={props.startLoading}
                    buttonLoading={props.userNameButtonLoading} />
                <SearchBar
                    key="employeeIdSearchBar"
                    name="employeeIdSearchBar"
                    title="Search by Employee ID"
                    inputPlaceholder="Example Employee ID: 102676514"
                    parameter='employeeId'
                    startLoading={props.startLoading} />
                <SearchBar
                    key="badgeIdSearchBar"
                    name="badgeIdSearchBar"
                    title="Search by Badge ID"
                    note="Note that OnGuard Badge Audit table is only available when searching by Badge ID and only the latest audit record is shown."
                    inputPlaceholder="Example Badge ID: 7060727"
                    parameter='badgeId'
                    startLoading={props.startLoading}
                    isInputValid={isBadgeIdInputValid} />
            </Container>
        </div>
    );
}