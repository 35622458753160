import { DateRangePickerProps } from "@amzn/awsui-components-react";
import { parseISO, sub } from "date-fns";
import React from "react";

export const invalidBadgeIdText = 'Badge Id must be an integer.'
export function isBadgeIdInputValid(input: string): boolean {
    return input !== undefined && (/^\-?\d+$/.test(input));
}

export function dateRangeToStartAndEndDates(value: DateRangePickerProps.Value | null) {
    let startDate: Date | undefined;
    let endDate: Date | undefined;

    if (value?.type === "relative") {
        endDate = new Date();
        switch (value.unit) {
            case "second":
                startDate = sub(endDate, { seconds: value.amount });
                break;
            case "minute":
                startDate = sub(endDate, { minutes: value.amount });
                break;
            case "hour":
                startDate = sub(endDate, { hours: value.amount });
                break;
            case "day":
                startDate = sub(endDate, { days: value.amount });
                break;
            case "month":
                startDate = sub(endDate, { months: value.amount });
                break;
            case "week":
                startDate = sub(endDate, { weeks: value.amount });
                break;
            case "year":
                startDate = sub(endDate, { years: value.amount });
                break;
        }
    } else if (value?.type === "absolute") {
        endDate = parseISO(value?.endDate);
        startDate = parseISO(value?.startDate);
    }
    if ((startDate && endDate) || (!startDate && !endDate)) {
        return {
            startTime: startDate?.getTime(),
            endTime: endDate?.getTime()
        }
    } else {
        throw new Error('could not parse dates from date range picker');
    }
}

export function getTableHeader(label: string) {
    return <h2 style={{ margin: "0.4em", textAlign: "center" }}> {label}</h2>
}