import { createState } from '@hookstate/core';

export interface IAppState {
	username?: string;
	isActionAuthorized: boolean;
}

export const appBaseState = createState<IAppState>({
	username: undefined,
	isActionAuthorized: false
});