
import { IDefaultQuery, ITableData } from "src/interfaces";
import { ITableProps } from "../Table";
import { IColumnDefinition } from "./tableConfig";
import { getTableComponentConfig } from "./tableComponentConfig";

export function getTableProps(
    label: string,
    individualTableData: ITableData,
    defaultColumnDefinitions: IColumnDefinition[],
    dynamicColumnDefinitions?: IColumnDefinition[],
    defaultQuery?: IDefaultQuery): ITableProps {

    return {
        label,
        loading: individualTableData.loadingMethodCountForTab > 0,
        tableComponentConfig: getTableComponentConfig(
            individualTableData.tableName,
            individualTableData.tableData,
            defaultColumnDefinitions,
            dynamicColumnDefinitions,
            defaultQuery),
        errorMessage: individualTableData.errorMessage,
    }
}