import React, { useEffect, useState } from "react";
import { Alert, Box, Cards, Header, Icon, Popover, Spinner, StatusIndicator, TextContent } from "@amzn/awsui-components-react";
import { getTableHeader } from "../utils";
import { ITableDatas } from "src/interfaces";
import { appBaseState } from "../store/appState";
import { useHookstate } from "@hookstate/core";
import { getPhotos } from "./tableConfigs/checkPhotoConfig";

interface ICheckPhotoTab {
    userLoading: boolean;
    userName: string;
    tableData: ITableDatas;
}

export function CheckPhotosTab(props: ICheckPhotoTab) {
    const [lookupUser, setLookupUser] = useState('nobody');
    const [showWarning, showActiveWarning] = useState(false);

    useEffect(() => {
        // Grab table data for looked up user
        const userData = props.tableData['Cardholder'].tableData.find(row => row['userName']);
        // PAPI data to determine employee status
        const userPAPIData = props.tableData['PeopleAPIV2EmployeeData'].tableData.find(row => row['login']);

        if (userData) {
            const isUserActive = !userPAPIData || userPAPIData['employeestatus'] !== 'A';
            const lookupAlias = userData['userName'];

            // Show employee status warning if status is not active
            showActiveWarning(isUserActive);
            // Default to nobody if no user is loaded
            setLookupUser(lookupAlias ?? 'nobody');
        } else {
            setLookupUser('nobody');
            showActiveWarning(false);
        }

    }, [props.tableData]);

    return (
        <div style={{
            marginLeft: '10%',
            marginRight: '10%'
        }}>
            {/* Display status warning if employee is not active */}
            <Box padding={{ bottom: 'xs' }}>
                {showWarning && <InactiveUserWarning />}
            </Box>
            <Cards
                header={(
                    <Header>Check Badge Photos</Header>
                )}
                items={getPhotos(lookupUser)}
                loading={props.userLoading}
                cardDefinition={{
                    header: item => (
                        <TextContent>
                            <div 
                                className="awsui-util-container-header awsui-util-action-stripe awsui-util-action-stripe-title" 
                                style={{ textAlign: 'center' }}
                            >
                                <h4 className='awsui-util-font-size-3'>
                                    <strong>
                                        {item.name}
                                    </strong>
                                    <Box color="text-status-info" display="inline" textAlign="right" padding={{ left: 'xs'}}>
                                        <Popover size="small" content={item.description}>
                                            <StatusIndicator type="info" />
                                        </Popover>
                                    </Box>
                                </h4>
                            </div>
                        </TextContent>
                    ),
                    sections: [
                        {
                            id: 'image',
                            content: (item) => (
                                <BadgePhotoImage imgSrc={item.source} />
                            )
                        },
                    ]
                }}
                cardsPerRow={[
                    { minWidth: 300, cards: 2 },
                    { minWidth: 600, cards: 3 },
                    { minWidth: 900, cards: 4 },
                    { minWidth: 1200, cards: 5 },
                    { minWidth: 1500, cards: 6 },
                ]}
            />
        </div>
    )
}

function BadgePhotoImage(props: {
    imgSrc: string;
}) {
    const [loading, setLoading] = useState(true);
    const [errorImage, showErrorImage] = useState(false);

    const imgLoadError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
        setLoading(false);
        showErrorImage(true);
    }

    useEffect(() => {
        setLoading(true);
        showErrorImage(false);
    }, [props.imgSrc]);

    return (
        <div>
            {loading && <Box textAlign="center"><Spinner size="large"/></Box>}
            <div style={{ textAlign: 'center' }}>
                {errorImage ? <PhotoLoadingError /> : (
                    <a target="_blank" href={props.imgSrc} >
                        <img
                            style={{
                                width: '140px',
                                borderRadius: '10px',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: 'auto',
                                alignSelf: 'auto',
                                display: loading ? 'none' : 'inherit'
                            }}
                            onLoad={() => setLoading(false)}
                            onError={(e) => {imgLoadError(e)}}
                            src={ props.imgSrc }
                        />
                    </a>
                )}
            </div>
        </div>
    )
}


function InactiveUserWarning() {
    /**
     * Display a warning since the user may be looking up an inactive alias
     * or employee select in Lanyard. This also follows the old CheckPhoto
     * behavior.
     */

    return (
        <Alert
            statusIconAriaLabel="Warning"
            type="warning"
        >
            CheckPhoto will lookup photos for active employees first. If the employee you
            looked up does not currently have an active status, their photo may be incorrect. Please
            verify the employee status before utilizing these photos.
        </Alert>
    )
}

function PhotoLoadingError() {
    return (
        <>
            <Box padding={{ bottom: 's' }}>
                <Icon name="status-negative" size="large" variant="error" />
            </Box>
            <TextContent>
                Unable to display image. You are not authorized to lookup
                photos or we encountered an issue loading the image. Please
                verify your access and try again.
            </TextContent>
        </>
    )
}
