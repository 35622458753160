import React from 'react';
import { CollectionPreferences } from '@amzn/awsui-components-react';
import { useCollection } from '@amzn/awsui-collection-hooks'
import { IColumnDefinition } from './tableConfig';
import { Pagination, PropertyFilter } from '@amzn/awsui-components-react';
import { IDefaultQuery } from 'src/interfaces';

interface IPreferences {
    visibleContent: string[],
    pageSize: number
}

export function getTableComponentConfig(
    tableName: string,
    tableData: any,
    defaultColumnDefs: IColumnDefinition[],
    dynamicColumnDefs?: IColumnDefinition[],
    defaultQuery?: IDefaultQuery) {

    const columnDefsWithoutIdColumn = dynamicColumnDefs ? dynamicColumnDefs : defaultColumnDefs;
    const defaultColumnDefsWithoutId = defaultColumnDefs;

    const [preferences, setPreferences] = React.useState<IPreferences>({
        ...getSavedTablePreferences(tableName, defaultColumnDefsWithoutId)
    });
    return {
        ...getTablePreferencesConfig(tableName, columnDefsWithoutIdColumn, preferences, setPreferences),
        ...getFilterAndSortConfig(tableData, columnDefsWithoutIdColumn, preferences, defaultQuery),
        columnDefinitions: defaultColumnDefs
    }
}

function getTablePreferencesConfig(
    tableName: string,
    defaultColumnDefs: IColumnDefinition[],
    preferences: IPreferences,
    setPreferences: React.Dispatch<React.SetStateAction<IPreferences>>) {

    const visibleColumnOptions = (defaultColumnDefs).map(definition => ({
        id: definition.id,
        label: definition.label
    }));

    const handleConfirm = (event: any) => {
        localStorage.setItem('tablePreferences', JSON.stringify({ [tableName]: event.detail }));
        setPreferences(event.detail as any);
    }

    return {
        preferences: <CollectionPreferences
            onConfirm={handleConfirm}
            preferences={preferences}
            visibleContentPreference={{
                title: "Select visible content",
                options: [
                    {
                        label: "Columns",
                        options: visibleColumnOptions
                    }
                ]
            }}
            cancelLabel="Cancel"
            confirmLabel="Confirm"
            title="Preferences"
            pageSizePreference={{
                title: "Select page size",
                options: [
                    { value: 50, label: "50 resources" },
                    { value: 100, label: "100 resources" },
                    { value: 500, label: "500 resources" },
                    { value: 800, label: "800 resources" },
                ],
            }}
        />,
        visibleColumns: preferences.visibleContent
    }
}

function getFilterAndSortConfig(
    tableData: any,
    columnDefs: IColumnDefinition[],
    preferences: IPreferences,
    defaultQuery?: IDefaultQuery) {

    const { items, filteredItemsCount, propertyFilterProps, collectionProps, paginationProps } = useCollection<any>(
        tableData,
        {
            propertyFiltering: {
                filteringProperties: columnDefs.map((definition: IColumnDefinition) => ({
                    key: definition.id,
                    propertyLabel: definition.label,
                    groupValuesLabel: definition.label,
                })),
                defaultQuery: {
                    tokens: defaultQuery?.filters || [],
                    operation: defaultQuery?.operation || "and",
                },
            },
            sorting: {},
            selection: {
                keepSelection: true
            },
            pagination: {
                defaultPage: 1,
                pageSize: preferences.pageSize
            }
        },
    );

    return {
        items,
        filter: getPropertyFilter(filteredItemsCount, propertyFilterProps),
        ...collectionProps,
        pagination: <Pagination {...paginationProps} />
    }
}

function getPropertyFilter(filteredItemsCount: number | undefined, propertyFilterProps: any) {
    return <PropertyFilter
        countText={`${filteredItemsCount} matches`}
        expandToViewport
        i18nStrings={{
            filteringAriaLabel: "your choice",
            dismissAriaLabel: "Dismiss",
            filteringPlaceholder:
                "Filter distributions by text, property or value",
            groupValuesText: "Values",
            groupPropertiesText: "Properties",
            operatorsText: "Operators",
            operationAndText: "and",
            operationOrText: "or",
            operatorLessText: "Less than",
            operatorLessOrEqualText: "Less than or equal",
            operatorGreaterText: "Greater than",
            operatorGreaterOrEqualText:
                "Greater than or equal",
            operatorContainsText: "Contains",
            operatorDoesNotContainText: "Does not contain",
            operatorEqualsText: "Equals",
            operatorDoesNotEqualText: "Does not equal",
            editTokenHeader: "Edit filter",
            propertyText: "Property",
            operatorText: "Operator",
            valueText: "Value",
            cancelActionText: "Cancel",
            applyActionText: "Apply",
            allPropertiesLabel: "All properties",
            tokenLimitShowMore: "Show more",
            tokenLimitShowFewer: "Show fewer",
            clearFiltersText: "Clear filters",
            removeTokenButtonAriaLabel: token =>
                `Remove token ${token.propertyKey} ${token.operator} ${token.value}`,
            enteredTextLabel: text => `Use: "${text}"`
        }}
        {...propertyFilterProps}
    />
}

function getSavedTablePreferences(tableName: string, columnDefs: IColumnDefinition[]): IPreferences {
    const savedPreferencesString = localStorage.getItem('tablePreferences');
    if (savedPreferencesString) {
        const preferences = JSON.parse(savedPreferencesString);
        if (preferences[tableName]) {
            return preferences[tableName];
        }
    }
    return {
        visibleContent: columnDefs.filter(definition => !definition.isHidden).map(definition => definition.id),
        pageSize: 50
    }
}
