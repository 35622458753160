import { Button, Container, Flashbar, FlashbarProps } from "@amzn/awsui-components-react";
import React from "react";
import { requestAction } from "src/api";
import styles from "../../styles/redriveContainer.module.scss";
import { IRedriveActionComponent, IRedriveComponentData } from "./RedriveActionComponents";
import { IActionResult } from "src/interfaces";


interface IRedriveActionContainer extends IRedriveComponentData {
    redriveComponent: IRedriveActionComponent
    flashbarData: FlashbarProps.MessageDefinition[]
    setFlashbarData: (flashbarData: FlashbarProps.MessageDefinition[]) => void
}

export function RedriveActionContainer({ flashbarData, setFlashbarData, redriveComponent, fieldData, setFieldData }: IRedriveActionContainer) {
    const action = async () => {
        setFlashbarData([{
            content: "Processing request",
            type: "success",
            loading: true,
            dismissible: true,
            onDismiss: () => { setFlashbarData([]) }
        }]);

        const result: IActionResult = await requestAction(
            redriveComponent.methodName,
            {
                ...redriveComponent.getDataForRequest(fieldData)
            }
        );

        setFieldData((prev) => ({
            ...prev,
            requestErrorText: result
        }));

        setFlashbarData([
            {
                content: result.status === "error" ? `Request failed: ${result.message}` : result.message ?? 'Successfully submitted request',
                type: result.status,
                dismissible: true,
                onDismiss: () => { setFlashbarData([]) }
            },
            ...(redriveComponent.getAdditionalFlashbarContent?.(
                fieldData,
                () => { setFlashbarData([]) },
                !result.message) ?? [])
        ]);
    };


    return <div>
        <Container>
            <div className={styles.content}>
                <div
                    key='redriveStatusFlashbar'
                    className={styles.statusFlasbar}>
                    <Flashbar
                        key="redriveStatusFlashbar"
                        items={flashbarData}
                    />
                </div>
                {redriveComponent.fields}
                <Button
                    disabled={(flashbarData.length > 0 && flashbarData[0].loading) || redriveComponent.isInvalid(fieldData)}
                    onClick={action}
                >Redrive</Button>
            </div>
        </Container>
    </div>
}