import { Spinner, TextContent } from "@amzn/awsui-components-react";
import { State } from "@hookstate/core";
import { Auth } from "aws-amplify";
import React, { useEffect, useState } from "react";
import styles from "../../styles/auth.module.scss";
import { IAppState } from '../store/appState';
import { isActionAuthorized, logUserAction } from "src/api";
import axios from "axios";

enum LoginState {
    loggedIn,
    loading,
    loginFailed
}

const getTitle = (children: any) => {
    return <div className={styles.position}>
        <TextContent >
            <h1>{children}</h1>
        </TextContent>
    </div>
}

interface IAuthProps {
    appState: State<IAppState>;
    children: any;
}

export function AuthenticateUser({ appState, children }: IAuthProps) {
    const [display, setDisplay] = useState<LoginState>(
        appState.username ? LoginState.loggedIn : LoginState.loading);

    const setUserData = (cognitoUserData: any) => {
        const idToken = cognitoUserData.getSignInUserSession().getIdToken();
        const userId = idToken.payload['identities'][0].userId;
        appState.username.set(userId);
    }

    const getActionAuthorization = async () => {
        isActionAuthorized().then(isAuthorized => {
            appState.isActionAuthorized.set(isAuthorized);
        }).catch(() => {
            appState.isActionAuthorized.set(false);
        })
    }

    useEffect(() => {
        Auth.currentAuthenticatedUser().then((cognitoUserData) => {
            setUserData(cognitoUserData);
            logUserAction({
                actionName: "successfulAuthentication",
            });
            getActionAuthorization();
            setDisplay(LoginState.loggedIn);
        }).catch(() => {
            console.log('No user data found, please sign in..');
            setDisplay(LoginState.loading);
            Auth.federatedSignIn({
                customProvider: 'AmazonFederate'
            }).catch((err: Error) => {
                console.error('Error trying to sign user in via Federate..', err);
                setDisplay(LoginState.loginFailed);
            })
        });
    }, []);

    switch (display) {
        case LoginState.loggedIn:
            return children;
        case LoginState.loading:
            return getTitle(<>Authenticating User...<Spinner size="big" /></>);
        default:
            return getTitle('Error trying to sign user in via Federate..');
    }
}