import React from 'react';
import { IsItDown } from '../IsItDown';
import { NavigationBar } from './NavigationBar';

export function BaseLayout(props: any) {
    return <>
        <NavigationBar />
        <IsItDown/>
        {props.children}
    </>

}  