import React from "react";
import { IDefaultQuery, ITableData, ITableDatas } from "src/interfaces";

interface ITableDefinition {
    label: string
    methodDefinitions: IMethodDefinition[]
    defaultColumns: string[]
    dynamicColumns?: string[]
    defaultQuery?: IDefaultQuery
    dynamicallyLoaded?: boolean
    // column names in the definition are used for displaying too, so it needs to be
    // converted to camelcase to pull the data to display them, but sometimes
    // column names are directly used for pulling the data.
    ignoreCamelCasing?: boolean
}

export interface IMethodDefinition {
    method: string
    allowedParams: string[]
    calledManually?: boolean
}

interface IColumnDefinitions {
    [key: string]: IColumnDefinition[];
}

export interface IColumnDefinition {
    id: string;
    header: JSX.Element | string;
    label: string;
    cell(e: any): JSX.Element | string;
    sortingField: string;
    isHidden?: boolean;
    maxWidth?: string | number;
}

export const tableDefinitions: { [key: string]: ITableDefinition } = {
    Cardholder: {
        label: "Cardholder",
        methodDefinitions: [
            {
                method: "Lanyard_OnGuard_Cardholder",
                allowedParams: ["employeeId", "badgeId", "userName"],
            },
            {
                method: "Feenics",
                allowedParams: ["employeeId", "badgeId", "userName"],
                calledManually: true
            }
        ],
        defaultColumns: [
            "Source",
            "Stack",
            "Source Region",
            "First Name",
            "Last Name",
            "Home Region",
            "Employee Id",
            "User Name",
            "Barcode",
            "Employee Status",
            "Employee Type",
            "Feenics Object Key",
            "Feenics Person Id"
        ]
    },

    Badge: {
        label: "Badge",
        methodDefinitions: [
            {
                method: "Lanyard_OnGuard_Badge",
                allowedParams: ["employeeId", "badgeId", "userName"],
            },
            {
                method: "Feenics",
                allowedParams: ["employeeId", "badgeId", "userName"],
                calledManually: true
            }
        ],
        defaultColumns: [
            "Source",
            "Stack",
            "Source Region",
            "Badge Id",
            "Badge Status",
            "Badge Type",
            "Activated",
            "Deactivated",
            "First Name",
            "Last Name",
            "Home Region",
            "Employee Id",
            "User Name",
            "Badge Last Change Date",
            "Feenics Object Key",
            "Display Card Number",
            "Last Used Date Time"
        ]
    },

    AccessLevel: {
        label: "Access Levels",
        methodDefinitions: [
            {
                method: "Lanyard_OnGuard_AccessLevels",
                allowedParams: ["employeeId", "badgeId", "userName"],
            },
            {
                method: "Feenics",
                allowedParams: ["employeeId", "badgeId", "userName"],
                calledManually: true
            }
        ],
        defaultColumns: [
            "Source",
            "Stack",
            "Source Region",
            "Badge Id",
            "Badge Key",
            "Badge Status",
            "Badge Type",
            "Access Level Id",
            "Access Level Description",
            "Activation Date",
            "Deactivation Date",
            "Employee Id",
            "Feenics Object Key",
        ],
        defaultQuery: {
            filters: [
                {
                    operator: "=",
                    value: "Active",
                    propertyKey: "badgeStatus"
                },
                {
                    operator: "=",
                    value: "Feenics",
                    propertyKey: "source"
                }
            ],
            operation: "or"
        }
    },

    CardholderDataCentral: {
        label: "CDC MSSQL",
        methodDefinitions: [
            {
                method: "Lanyard_CardholderDataCentral",
                allowedParams: ["employeeId", "badgeId", "userName"]
            }
        ],
        defaultColumns: [
            "Stack",
            "Source Region",
            "Id",
            "Badge Id",
            "Badge Status",
            "Badge Type",
            "Activated",
            "Deactivated",
            "Last Update Source Region",
            "Last Update Source System",
            "Last Update User Home Region",
            "PeopleSoftID",
            "Access Revocation",
            "Last Updated",
            "_MSSQL_insert_utctimestamp",
            "_MSSQL_lastupdate_utctimestamp"]
    },

    OnGuard_Audit_BadgeID: {
        label: "OnGuard Badge Audit",
        methodDefinitions: [
            {
                method: "Lanyard_OnGuard_Audit_BadgeID",
                allowedParams: ["badgeId"]
            }
        ],
        defaultColumns: [
            "Stack",
            "Source Region",
            "Audit Id",
            "Badge Id",
            "Issue Code",
            "Employee Id",
            "Status",
            "Type",
            "Activate",
            "Deactivate",
            "Prints",
            "Last Print",
            "Last Changed",
            "Badge Key",
            "Segment Id",
            "Audit Action",
            "Audit Date UTC",
            "Audit User",
            "Audit App",
            "Region"
        ],
        defaultQuery: {
            filters: [
                {
                    operator: "=",
                    value: "Active",
                    propertyKey: "status"
                }
            ],
            operation: "and"
        }
    },

    Lanyard_BadgeDataAPIV2: {
        label: "Badge Data API V2",
        methodDefinitions: [
            {
                method: "Lanyard_BadgeDataAPIV2",
                allowedParams: ["employeeId", "badgeId", "userName"]
            }
        ],
        defaultColumns: [
            "Database",
            "Badge ID",
            "Badge Status",
            "Badge Type",
            "Activate Date Time",
            "Deactivate Date Time",
            "Last Update Source Region",
            "Last Update Source System",
            "Last Update User HomeRegion",
            "Last Updated DynamoDB",
            "PeopleSoftID",
            'UID',
            "last_updatedISO",
        ]
    },
    "Lanyard_BadgeDataAPIV2.2": {
        label: "Badge Data API V2.2",
        methodDefinitions: [
            {
                method: "Lanyard_BadgeDataAPIV2.2",
                allowedParams: ["employeeId", "badgeId", "userName"]
            }
        ],
        defaultColumns: [
            "Database",
            "Badge ID",
            "Badge Status",
            "Badge Type",
            "Activate Date Time",
            "Deactivate Date Time",
            "Last Update Source Region",
            "Last Update Source System",
            "Last Update User HomeRegion",
            "Last Updated DynamoDB",
            "PeopleSoftID",
            'UID',
            "last_updatedISO",
            "Application_560001",
            "Application_560002",
            "Application_F51CDB",
            "CardType",
            "DesfireCSN"
        ]
    },
    EmployeeDataForLenelv2: {
        label: "EmployeeDataForLenelv2 MSSQL",
        methodDefinitions: [
            {
                method: "Lanyard_EmployeeDataForLenelv2",
                allowedParams: ["employeeId", "badgeId", "userName"]
            }
        ],
        defaultColumns: [
            "Stack",
            "Source Region",
            "Login",
            "First Name",
            "Last Name",
            "Employee Id",
            "Employee Status",
            "Person Id",
            "Primary Internal Email Address",
            "Barcode",
            "Job Worker Type",
            "Access Removal Date",
            "Access Revocation Date",
            "Building Code",
            "Country",
            "Region",
            "Work Location Name",
        ]
    },
    PeopleAPIV2EmployeeData: {
        label: "PeopleAPIV2-EmployeeData DDB",
        methodDefinitions: [
            {
                method: "PeopleAPIV2EmployeeData",
                allowedParams: ["employeeId", "userName"]
            }
        ],
        dynamicallyLoaded: true,
        ignoreCamelCasing: true,
        defaultColumns: [
            "login",
            "psid",
            "firstname",
            "lastname",
            "employeestatus",
            "barcode",
            "badgecolor",
            "access_revocation",
            "buildingcode",
            "country",
            "SOTPeopleAPIv2lastUpdateTime",
        ]
    },

    BadgeAssignmentTracking: {
        label: "Badge Assignment Tracking DDB",
        methodDefinitions: [
            {
                method: "BadgeAssignmentTracking",
                allowedParams: ["employeeId", "badgeId", "userName"]
            }
        ],
        ignoreCamelCasing: true,
        defaultColumns: [
            "BadgeID",
            "ActivateDateTime",
            "BadgeStatus",
            "BadgeType",
            "DeactivateDateTime",
            "LastUpdatedDynamoDB",
            "LastUpdateSourceRegion",
            "LastUpdateSourceSystem",
            "LastUpdateUserHomeRegion",
            "PeopleSoftID",
            "LastLocationReaderName",
            "LastLocationTimestamp",
            "LastLocationEventType",
            "LastLocationDeviceId",
            "Application_560001",
            "Application_560002",
            "Application_F51CDB",
            "CardType",
            "DesfireCSN"
        ]
    },
}

interface ICustomColumnDefinition {
    [key: string]: {
        id: string,
        header: string,
    }
}

const customColumnDefinitions: { [key: string]: ICustomColumnDefinition } = {

    CardholderDataCentral: {
        "Deactivated": {
            id: "deactivationDateTime",
            header: "Deactivated",
        },
        "Activated": {
            id: "activationDateTime",
            header: "Activated",
        },
        "Access Revocation": {
            id: "accessRevocationDateTime",
            header: "Access Revocation",
        },
        "PeopleSoftID": {
            id: "peopleSoftId",
            header: "PeopleSoftID",
        },
    },

    OnGuard_Audit_BadgeID: {
        "Audit Date UTC": {
            id: "auditDateUTC",
            header: "Audit Date UTC",
        },
    },
    Lanyard_BadgeDataAPIV2: {
        "last_updatedISO": {
            id: "last_updatedISO",
            header: "Last Updated Date Time ISO",
        },

        "Activate Date Time": {
            id: "ActivateDateTimeISO",
            header: "Activate Date Time",
        },

        "Deactivate Date Time": {
            id: "DeactivateDateTimeISO",
            header: "Deactivate Date Time",
        },
    },
    
    "Lanyard_BadgeDataAPIV2.2": {
        "last_updatedISO": {
            id: "last_updatedISO",
            header: "Last Updated Date Time ISO",
        },

        "Activate Date Time": {
            id: "ActivateDateTimeISO",
            header: "Activate Date Time",
        },

        "Deactivate Date Time": {
            id: "DeactivateDateTimeISO",
            header: "Deactivate Date Time",
        },
    }
}

const toCamelCase = (str: string) => {
    return str
        .replace(/\s(.)/g, function ($1) { return $1.toUpperCase(); })
        .replace(/\s/g, '')
        .replace(/^(.)/, function ($1) { return $1.toLowerCase(); });
}

const getCenteredText = (str: string) => {
    return <div style={{ textAlign: 'center' }}>{str}</div>;
}

const generateColumnDefinitions = (): IColumnDefinitions => {
    const definitions: IColumnDefinitions = {};
    for (const tableName of Object.keys(tableDefinitions)) {
        definitions[tableName] = tableDefinitions[tableName].defaultColumns.map((columnName: string) => {
            let camelCasedName = tableDefinitions[tableName].ignoreCamelCasing ? columnName : toCamelCase(columnName);
            if (tableName === 'Lanyard_BadgeDataAPIV2' &&
                !customColumnDefinitions.Lanyard_BadgeDataAPIV2[columnName]) {
                camelCasedName = camelCasedName.charAt(0).toUpperCase() + camelCasedName.substring(1);
            }
            if (tableName === 'Lanyard_BadgeDataAPIV2.2' &&
                !customColumnDefinitions["Lanyard_BadgeDataAPIV2.2"][columnName]) {
                camelCasedName = camelCasedName.charAt(0).toUpperCase() + camelCasedName.substring(1);
            }
            if (customColumnDefinitions[tableName] && customColumnDefinitions[tableName][columnName]) {
                const customColumnDefinition = customColumnDefinitions[tableName][columnName];
                return getColumnDefinition(customColumnDefinition.id, customColumnDefinition.header);
            } else {
                return getColumnDefinition(camelCasedName, columnName);
            }
        });

        // used for sorting and filtering
        if (!definitions[tableName].filter((def: IColumnDefinition) => def.id === 'id')) {
            definitions[tableName].push(getTableIndexColumnDef());
        }
    }
    return definitions;
}

export const columnDefinitions: IColumnDefinitions = generateColumnDefinitions();

export const getBlankTableData = (): ITableDatas => {
    const result: ITableDatas = {};
    for (let definitionKey of Object.keys(tableDefinitions)) {
        result[definitionKey] = {
            loadingMethodCountForTab: 0,
            tableSupported: true,
            tableData: [],
            errors: [],
            tableName: definitionKey
        };
    }
    return result;
}

export const tableSupportsParam = (tableName: string, paramName: string) => {
    for (const methodDefinition of tableDefinitions[tableName].methodDefinitions) {
        if (methodDefinition.allowedParams.includes(paramName)) {
            return true;
        }
    }
    return false;
}

export const tableSupportsParamCount = (tableName: string, paramName: string) => {
    let count = 0;
    for (const methodDefinition of tableDefinitions[tableName].methodDefinitions) {
        if (methodDefinition.allowedParams.includes(paramName)) {
            count = count + 1;
        }
    }
    return count;
}

export const generateColumnsDynamically = (tableData: ITableData) => {
    if (tableData.tableData.length > 0) {
        const columnDefs: IColumnDefinition[] =
            Object.keys(tableData.tableData[0]).sort().map(columnName => getColumnDefinition(columnName));
        if (!Object.keys(tableData.tableData[0]).includes('id')) {
            columnDefs.push(getTableIndexColumnDef());
        }
        return columnDefs;
    }
    return undefined;
}

export function getColumnDefinition(id: string, label?: string, maxWidth?: number | string): IColumnDefinition {
    label = label ?? id;
    return {
        id: id,
        header: label,
        label: label,
        cell: (e: any) => getCenteredText(e[id]),
        sortingField: id,
        maxWidth: maxWidth ? `${maxWidth}px` : undefined
    }
}

function getTableIndexColumnDef(): IColumnDefinition {
    return getColumnDefinition('id');
}